import React, { useState, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { Link } from "react-router-dom";
import {
  requestDice,
  diamondCard,
  dice,
  coin,
  slideBase,
  heartsButton,
  bottomHeart,
  bottomSpade,
  lightBG,
  mobileBG,
} from "../../common/assets/images";
import { useGSAP } from "@gsap/react";
import { APIS_GamesBooks } from "../../helpers/ApiConsumo";

import image1 from  "../../common/assets/slide_1-min.png"
import image2 from  "../../common/assets/slide_2-min.png"
import image3 from  "../../common/assets/slide_3-min.png"
import useUserStore from "../../store/store";
gsap.registerPlugin(ScrollTrigger);

const MobileSS = () => {



  const userData = useUserStore((state) => state.userData);

  const [Casino,setCasino] = useState([])
  const [resok,setResok] = useState(0)

  const [colocarUsuario, setColocarUsuario] = useState([])

  const getUsers = async () => {

    const usuarios = await APIS_GamesBooks('getPackage');
    setResok(resok + 1)
    setColocarUsuario(usuarios)
    console.log(usuarios)
}

useEffect(() => {
  getUsers();
}, [])

useEffect(() => {
  setCasino(colocarUsuario);
}, [resok])




  useGSAP(() => {
    // gsap.set("mI1-div", { scale: 0.5 });
    // gsap.set("mI2-div", { scale: 0.5 });
    // gsap.set("mI3-div", { scale: 0.5 });
    // ScrollTrigger.create({
    //   trigger: ".mI1-div",
    //   start: "top 80%",
    //   end: "bottom",
    //   scrub: 1,
    //   animation: gsap.timeline().to(".mI1-div", {
    //     scale: 1.2,
    //   }),
    // });
    // ScrollTrigger.create({
    //   trigger: ".mI2-div",
    //   start: "top 80%",
    //   end: "bottom",
    //   scrub: 1,
    //   animation: gsap.timeline().to(".mI2-div", {
    //     scale: 1.2,
    //   }),
    // });
    // ScrollTrigger.create({
    //   trigger: ".mI3-div",
    //   start: "top 80%",
    //   end: "bottom",
    //   scrub: 1,
    //   animation: gsap.timeline().to(".mI3-div", {
    //     scale: 1.2,
    //   }),
    // });
    // ScrollTrigger.create({
    //   trigger: ".mI2",
    //   start:  "bottom  "  ,
    //   end: "+=200",
    //   scrub: 1.5,
    //   markers: {
    //     startColor: 'green'
    //   },
    //   animation: gsap.timeline().to(".mI2-div", {
    //     yPercent: -100,
    //     x: 0,
    //     opacity: 1,
    //     scale: 1.2,
    //   }).to(".mI1-div", {
    //     yPercent: -200,
    //     x: 0,
    //     opacity: 1,
    //   }, "<") ,
    // });
    // ScrollTrigger.create({
    //   trigger: ".mI3",
    //   start:  "bottom  "  ,
    //   end: "+=200",
    //   scrub: 1.5,
    //   markers: {
    //     startColor: 'yellow'
    //   },
    //   animation: gsap.timeline().to(".mI3-div", {
    //     yPercent: -100,
    //     x: 0,
    //     opacity: 1,
    //   }).to(".mI2-div", {
    //     yPercent: -200,
    //     x: 0,
    //     opacity: 1,
    //   }, "<") ,
    // });

    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 

    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 

    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 

    ScrollTrigger.create({
      trigger: ".footer",
      start: "4% 80%",
      end: "+=200",
      scrub: 1.5,
      animation: gsap.timeline().to(".B1", {
        y: -70,
        x: 0,
        duration: 1,
        opacity: 1,
      }),
    });

    ScrollTrigger.create({
      trigger: ".footer",
      start: "4% 80%",
      end: "+=200",
      scrub: 1.5,
      animation: gsap.timeline().to(".B1", {
        y: -70,
        x: 0,
        duration: 1,
        opacity: 1,
      }),
    });

    ScrollTrigger.create({
      trigger: ".footer",
      start: "30% 80%",
      end: "+=100",
      scrub: 1,
      animation: gsap.timeline().to(".BI2", {
        y: 0,
        // x: 0,
        rotate: -178.11,
      }),
    });

    ScrollTrigger.create({
      trigger: ".footer",
      start: "30% 80%",
      end: "+=100",
      scrub: 1,
      toggleActions: "play",
      animation: gsap.timeline().to(".BI1", {
        y: 0,
        // x: 0,
        rotate: -188.11,
      }),
    });

  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${mobileBG})`,
      }}
      className="scrollElement"
    >
      <div className="second-section-container">
        <div className="second-section-div">
          <div className="second-first-div">
            <span className="cormorant-font second-first-title">
              With over 25 years of experience in the casino party industry.
            </span>
            <p className="second-first-description gordita-font">
              We guarantee you will feel like you're in Vegas!  Whether you're
              hosting a small 25 person home birthday party or a large 5000 plus
              person corporate event, Double Down Casino Events is committed to
              providing you with the highest quality equipment and best service
              available. All of our events are ran by a professionally trained
              pit boss  to ensure your event runs flawlessly.
              <br />
              <br />
              What is the difference between Double Down Casino Events and all of
              the other casino party companies? Reliability, competitive
              pricing, and state of the art equipment. Request a quote today to
              see why we are the best in casino rentals in Texas!
            </p>
            <a href={"/quote"} style={{ textDecoration: "none" }}>
              <button className="cormorant-font second-first-btn">
                REQUEST A QUOTE <img alt="request-dice" src={requestDice} />
              </button>
            </a>
          </div>
        </div>

        <div className="second-section-divii">
          <div></div>
          <div className="second-first-divII">
            <span className="cormorant-font second-first-title">
              Casino Night
            </span>
            <p className="second-first-description gordita-font">
              Host a Vegas style casino night for your friends and colleagues
              with Double Down Casino Events. We provide unsurpassed casino
              night rentals to the great state of Texas. Our 100% handmade in
              Texas, full-sized casino rentals will make your party
              unforgettable. We will bring the fun and energy of Las Vegas to
              all sized parties. Casino nights are great for birthday parties,
              holidays, weddings, corporate events, and many other occasions.
              Many companies have found that hosting a casino night boosts
              morale and camaraderie among their employees. With over 25 years
              of experience in the casino rental industry, not only will your
              party have state of the art casino equipment you will also have
              the best dealers around. Our dealers are trained in the Bellagio
              style to give your guests the friendliest most authentic
              experience. Give one of our expert event planners a call to see
              why Double Down Casino Events is your best choice.
            </p>
            <a style={{ textDecoration: "none" }} href={"/quote"}>
              <button className="cormorant-font second-first-btn">
                REQUEST A QUOTE <img alt="request-dice" src={requestDice} />
              </button>
            </a>
          </div>
        </div>

        {/* LINE */}
        <div className="second-section-line-container">
          <div className="second-section-line-innercontainer">
            <div className="second-section-line"></div>
            {/* DOT I */}
            <div className="second-section-dot-i"></div>
            {/* DOTII */}
            <div className="second-section-dot-ii"></div>
          </div>
        </div>
      </div>
      <div className="mthree-items-div-top-spacer"></div>
      {/* Three Items */}
      <div className="mthree-items-div">
        
       {Casino?.slice(0, 3).map((items, index) => (
        <div className="mthree-div mI1-div">
        <img
          src={items.image_url}
          alt="diamondCard"
          className="mI1 floating-img"
        />
        <img src={slideBase} alt="diamondCard" className="mI1B" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "0px",
          }}
          className="mI1C"
        >
          <span className="cormorant-font gradient-text mthree-div-title">
            {items.name}
          </span>

          <ul className="mthree-div-list">
        {items?.features?.map((item)=>(
        <li>{item}</li>
        ))}
      </ul>
      <span className="cormorant-font gradient-text mthree-div-title2">
            ${items.price}
      </span>
      {userData===null && !userData ? <a
        href={`/single-product/${items.id}`}
        style={{ textDecoration: "none" }}
        className="cormorant-font three-div-btn"
      >
        VIEW NOW <img alt="heartsButton" src={heartsButton} />
      </a> : <a
        href={`/single-product/${items.id}`}
        style={{ textDecoration: "none" }}
        className="cormorant-font three-div-btn"
      >
        RENT NOW <img alt="heartsButton" src={heartsButton} />
      </a>}
        </div>
      </div>
       ))}
        
      </div>

      {/* CALL US SECTION */}
      <div className="callus-section-container">
        <span className="callus-text cormorant-font">CALL US TODAY !</span>
        <a style={{textDecoration:'none'}} target="blank"  
  href="https://wa.me/15129452363"  className="callus-tel cormorant-font">+1 (512)-945-2363</a>
        <a href={"/quote"} style={{ textDecoration: "none" }}>
          <button className="cormorant-font callus-btn">
            REQUEST A QUOTE <img alt="request-dice" src={requestDice} />
          </button>
        </a>
      </div>
      {/* FOOTER */}
      <div className="footer">
        <div className="B1 footer-text-container">
        <a style={{textDecoration:'none'}} target="blank"  href="mailto:admin@doubledowncasinoevents.com" className="cormorant-font footer-text-I B1">
        admin@doubledowncasinoevents.com
          </a>
          <a style={{textDecoration:'none'}} href="https://www.marcelodesignx.com/" target="blank" className="cormorant-font footer-text-II B1">By MDX</a>
        </div>
        <div className="footer-img-container">
          <img src={bottomSpade} alt="" className="BI1 footer-img-I" />
          <img src={bottomHeart} alt="" className="BI2 footer-img-II" />
        </div>
      </div>
    </div>
  );
};

const DesktopSS = () => {

  useGSAP(() => {
  

    ScrollTrigger.create({
      trigger: ".footer",
      start: "4% 80%",
      end: "+=200",
      scrub: 1.5,
      animation: gsap.timeline().to(".B1", {
        y: -70,
        x: 0,
        duration: 1,
        opacity: 1,
      }),
    });

    ScrollTrigger.create({
      trigger: ".footer",
      start: "4% 80%",
      end: "+=200",
      scrub: 1.5,
      animation: gsap.timeline().to(".B1", {
        y: -70,
        x: 0,
        duration: 1,
        opacity: 1,
      }),
    });

    ScrollTrigger.create({
      trigger: ".footer",
      start: "30% 80%",
      end: "+=100",
      scrub: 1,
      animation: gsap.timeline().to(".BI2", {
        y: 0,
        // x: 0,
        rotate: -178.11,
      }),
    });

    ScrollTrigger.create({
      trigger: ".footer",
      start: "30% 80%",
      end: "+=100",
      scrub: 1,
      toggleActions: "play",
      animation: gsap.timeline().to(".BI1", {
        y: 0,
        // x: 0,
        rotate: -188.11,
      }),
    });

  }, []);

  const userData = useUserStore((state) => state.userData);

  const [Casino,setCasino] = useState([])
  const [resok,setResok] = useState(0)

  const [colocarUsuario, setColocarUsuario] = useState([])

  const getUsers = async () => {
    const usuarios = await APIS_GamesBooks('getPackage');
    setResok(resok + 1)
    setColocarUsuario(usuarios)
    console.log(usuarios)
}

useEffect(() => {
  getUsers();
}, [])

useEffect(() => {
  setCasino(colocarUsuario);
}, [resok])


  useGSAP(() => {
    let mm = gsap.matchMedia();
    mm.add(
      {
        isMobile: "(max-width: 600px)",
        isDesktop: "(max-width: 1600px)",
        isLargeDesktop: "(min-width: 1799px)",
      },
      (context) => {
        let { isMobile, isDesktop, isLargeDesktop } = context.conditions;


        gsap.to(".I1", {
          scale: isMobile ? 1.5 : 1.3,
          // y: isDesktop ? "-22vh" : isLargeDesktop ? "-22vh" : "-22vh", //-130
           //x: isDesktop ? "6vw" : isLargeDesktop ? "12vw" : "0", //60
          duration: 2,
          scrollTrigger: {
            trigger: isMobile
              ? ".three-items-div-top-spacer"
              : ".three-items-div",
            start: "center",
            end: "+=50%",
            scrub: 1,
            // toggleActions: "play none none none",
          },
        });

        gsap.to(".I1B", {
          scale: isMobile ? 1.5 : 1.3,
          // y: isDesktop ? "-20vh" : isLargeDesktop ? "-22vh" : "-22vh", //-130
          // x: isDesktop ? "5vw" : isLargeDesktop ? "12vw" : "0",
          duration: 1,
          scrollTrigger: {
            trigger: isMobile
              ? ".three-items-div-top-spacer"
              : ".three-items-div",
            start: "center",
            end: "+=50%",
            // toggleActions: "play none none none",
          },
        });

         gsap.to(".I1C", {
           // y: isDesktop ? "-20vh" : isLargeDesktop ? "-22vh" : "-22vh", //-130
           // x: isDesktop ? "5vw" : isLargeDesktop ? "12vw" : "0",
           duration: 2,
           scrollTrigger: {
             trigger: isMobile
               ? ".three-items-div-top-spacer"
               : ".three-items-div",
             start: isDesktop
               ? "bottom"
               : isLargeDesktop
               ? "center"
               : "center bottom",
             end: "+=250",
             scrub: 1,
             toggleActions: "play none none none",
           },
         });

        gsap.to(".I2", {
          scale: isMobile ? 1.5 : 1.3,
           //y: isDesktop ? "-20vh" : isLargeDesktop ? "-22vh" : "-22vh", //-130
          // x: 0,
          duration: 2,
          scrollTrigger: {
            trigger: isMobile
              ? ".three-items-div-top-spacer"
              : ".three-items-div",
            start: "center",
            end: "+=50%",
            scrub: 1,
            // toggleActions: "play none none none",
          },
        });

        gsap.to(".I2B", {
          scale: isMobile ? 1.5 : 1.3,
           //y: isDesktop ? "-20vh" : isLargeDesktop ? "-22vh" : "-22vh", //-130
          // x: 0,
          duration: 2,
          scrollTrigger: {
            trigger: isMobile
              ? ".three-items-div-top-spacer"
              : ".three-items-div",
            start: "center",
            end: "+=50%",
            scrub: 1,
            // toggleActions: "play none none none",
          },
        });

        // gsap.to(".I2C", {
        //   // y: isDesktop ? "-20vh" : isLargeDesktop ? "-22vh" : "-22vh", //-130
        //   // x: 0,
        //   duration: 2,
        //   scrollTrigger: {
        //     trigger: isMobile
        //       ? ".three-items-div-top-spacer"
        //       : ".three-items-div",
        //     start: isDesktop
        //       ? "bottom"
        //       : isLargeDesktop
        //       ? "center"
        //       : "center bottom",
        //     end: "+=250",
        //     scrub: 1,
        //     toggleActions: "play none none none",
        //   },
        // });

        gsap.from("#line-desk", {
          height:0 ,
          duration: 2,
         // y: isDesktop ? "-22vh" : "-22vh", //-130
         // x: isDesktop ? "-6vw" : isLargeDesktop ? "-12vw" : 0, //-60
          scrollTrigger: {
            trigger: isMobile
              ? ".second-section-line"
              : ".second-section-line",
            start: "top center",
            end: "+=160%",
            scrub: 1,
            // markers: {startColor: 'red'},
            // toggleActions: "play none none none",
          },
        });

        gsap.from("#dot-desk2", {
          opacity:0 ,
          scale:0,
          duration: 2,
         // y: isDesktop ? "-22vh" : "-22vh", //-130
         // x: isDesktop ? "-6vw" : isLargeDesktop ? "-12vw" : 0, //-60
          scrollTrigger: {
            trigger: isMobile
              ? ".second-section-line"
              : ".second-section-line",
            start: "top center",
            end: "+=100%",
            scrub: 1,
            // markers: {startColor: 'red'},
            // toggleActions: "play none none none",
          },
        });

        gsap.from("#dot-desk1", {
          opacity:0 ,
          scale:0,
          duration: 2,
         // y: isDesktop ? "-22vh" : "-22vh", //-130
         // x: isDesktop ? "-6vw" : isLargeDesktop ? "-12vw" : 0, //-60
          scrollTrigger: {
            trigger: isMobile
              ? ".second-section-line"
              : ".second-section-line",
            start: "top center",
            end: "+=30%",
            scrub: 1,
            // markers: {startColor: 'red'},
            // toggleActions: "play none none none",
          },
        });

        gsap.to(".I3", {
          scale: isMobile ? 1.5 : 1.3,
          duration: 2,
         // y: isDesktop ? "-22vh" : "-22vh", //-130
         // x: isDesktop ? "-6vw" : isLargeDesktop ? "-12vw" : 0, //-60
          scrollTrigger: {
            trigger: isMobile
              ? ".three-items-div-top-spacer"
              : ".three-items-div",
            start: "center",
            end: "+=50%",
            scrub: 1,
            // markers: {startColor: 'red'},
            // toggleActions: "play none none none",
          },
        });

        gsap.to(".I3B", {
          scale: isMobile ? 1.5 : 1.3,
          // y: isDesktop ? "-20vh" : "-22vh", //-120
          // x: isDesktop ? "-5vw" : isLargeDesktop ? "-12vw" : "0", //-50
          duration: 2,
          scrollTrigger: {
            trigger: isMobile
              ? ".three-items-div-top-spacer"
              : ".three-items-div",
            start: "center",
            end: "+=50%",
            scrub: 1,
            // toggleActions: "play none none none",
          },
        });
        // gsap.to(".I3C", {
        //   // y: isDesktop ? "-20vh" : "-22vh", //-120
        //   // x: isDesktop ? "-5vw" : isLargeDesktop ? "-12vw" : "0", //-50
        //   duration: 2,
        //   scrollTrigger: {
        //     trigger: isMobile
        //       ? ".three-items-div-top-spacer"
        //       : ".three-items-div",
        //     start: isDesktop
        //       ? "bottom"
        //       : isLargeDesktop
        //       ? "center"
        //       : "center bottom",
        //     end: "+=250",
        //     scrub: 1,
        //     toggleActions: "play none none none",
        //   },
        // });

      }
    );
  }, []);

  return (
    <div
      style={{
        background: 'linear-gradient(90deg, rgba(231, 185, 96, 0.00) 30% ,  rgba(231, 186, 96, 0.89) 70%,)'
      }}
      className="scrollElement"
    >
      <div className="second-section-container">
        <div className="second-section-div">
          <div className="second-first-div">
            <span className="cormorant-font second-first-title">
              With over 25 years of experience in the casino party industry.
            </span>
            <p className="second-first-description gordita-font">
              We guarantee you will feel like you're in Vegas!  Whether you're
              hosting a small 25 person home birthday party or a large 5000 plus
              person corporate event, Double Down Casino Events is committed to
              providing you with the highest quality equipment and best service
              available. All of our events are ran by a professionally trained
              pit boss  to ensure your event runs flawlessly.
              <br />
              <br />
              What is the difference between Double Down Casino Events and all of
              the other casino party companies? Reliability, competitive
              pricing, and state of the art equipment. Request a quote today to
              see why we are the best in casino rentals in Texas!
            </p>
            <a href={"/quote"} style={{ textDecoration: "none" }}>
              <button className="cormorant-font second-first-btn">
                REQUEST A QUOTE <img alt="request-dice" src={requestDice} />
              </button>
            </a>
          </div>
        </div>

        <div className="second-section-divii">
          <div></div>
          <div className="second-first-divII">
            <span className="cormorant-font second-first-title">
              Casino Night
            </span>
            <p className="second-first-description gordita-font">
              Host a Vegas style casino night for your friends and colleagues
              with Double Down Casino Events. We provide unsurpassed casino
              night rentals to the great state of Texas. Our 100% handmade in
              Texas, full-sized casino rentals will make your party
              unforgettable. We will bring the fun and energy of Las Vegas to
              all sized parties. Casino nights are great for birthday parties,
              holidays, weddings, corporate events, and many other occasions.
              Many companies have found that hosting a casino night boosts
              morale and camaraderie among their employees. With over 25 years
              of experience in the casino rental industry, not only will your
              party have state of the art casino equipment you will also have
              the best dealers around. Our dealers are trained in the Bellagio
              style to give your guests the friendliest most authentic
              experience. Give one of our expert event planners a call to see
              why Double Down Casino Events is your best choice.
            </p>
            <a style={{ textDecoration: "none" }} href={"/quote"}>
              <button className="cormorant-font second-first-btn">
                READ MORE
              </button>
            </a>
          </div>
        </div>

        {/* LINE */}
        <div className="second-section-line-container">
          <div className="second-section-line-innercontainer">
            <div id='line-desk' className="second-section-line"></div>
            {/* DOT I */}
            <div id='dot-desk1' className="second-section-dot-i"></div>
            {/* DOTII */}
            <div id='dot-desk2' className="second-section-dot-ii"></div>
          </div>
        </div>
      </div>
      <div className="three-items-div-top-spacer"></div>

      {/* CALL US SECTION */}

       <div className="three-items-div">
       {Casino?.slice(0, 3).map((items, index) => (
  <div className="three-div mt-three" key={index}>
    <img src={items?.image_url} alt="diamondCard" className="I1" />
    <img src={slideBase} alt="diamondCard" className="I1B" />
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "0px",
      }}
      className="I1C"
    >
      <span className="cormorant-font gradient-text three-div-title">
        {items.name}
      </span>
      <ul className="three-div-list">
        {items?.features?.map((item)=>(
        <li>{item}</li>
        ))}
      </ul>
        <span className="cormorant-font gradient-text three-div-title2">${items.price}</span>
      {userData===null && !userData ? <a
        href={`/single-product/${items.id}`}
        style={{ textDecoration: "none" }}
        className="cormorant-font three-div-btn"
      >
        VIEW NOW <img alt="heartsButton" src={heartsButton} />
      </a> : <a
        href={`/single-product/${items.id}`}
        style={{ textDecoration: "none" }}
        className="cormorant-font three-div-btn"
      >
        RENT NOW <img alt="heartsButton" src={heartsButton} />
      </a>}
    </div>
  </div>
))}
  
      </div>
      <div className="callus-section-container">
        <span className="callus-text cormorant-font">CALL US TODAY !</span>
        <a style={{textDecoration:'none'}} target="blank"  
  href="https://wa.me/15129452363"   className="callus-tel cormorant-font">+1 (512)-945-2363</a>
        <a href={"/quote"} style={{ textDecoration: "none" }} className="cormorant-font callus-btn">
            REQUEST A QUOTE <img alt="request-dice" src={requestDice} />
        </a>
      </div>
      {/* FOOTER */}
      <div className="footer">
        <div className="B1 footer-text-container">
        <a style={{textDecoration:'none'}} target="blank"  href="mailto:admin@doubledowncasinoevents.com" className="cormorant-font footer-text-I B1">
        admin@doubledowncasinoevents.com
        </a>
        <a style={{textDecoration:'none'}} href="https://www.marcelodesignx.com/" target="blank" className="cormorant-font footer-text-II B1">By MDX</a>
        </div>
        <div className="footer-img-container">
          <img src={bottomSpade} alt="" className="BI1 footer-img-I" />
          <img src={bottomHeart} alt="" className="BI2 footer-img-II" />
        </div>
      </div>
    </div>
  );
};
const SecondSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 968);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 968);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return <div> {isMobile ? <MobileSS /> : <DesktopSS />}</div>;
};

export default SecondSection;
