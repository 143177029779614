import React, { useEffect, useState } from "react";
import {
  sendIcon,
  rightContactbg,
  leftContactbg1,
  leftContactbg2,
  contactBg
} from "../../common/assets/images";
import useShowCart from "../../components/layout/useShowCart";
import useUserStore from "../../store/store";
import { useNavigate, useParams } from "react-router-dom";
import { APIS_GamesBooks } from "../../helpers/ApiConsumo";
import Popup from "../../components/Popup";

const Request = () => {
  const { id } = useParams();

  // Obtener los datos del usuario del estado de Zustand
  const [colocarUsuario, setColocarUsuario] = useState([]);
  const [colocarUsuario2, setColocarUsuario2] = useState([]);
  const [Casino, setCasino] = useState([]);
  const [resok, setResok] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Estado para controlar el Popup
  const [popupMessage, setPopupMessage] = useState(""); // Mensaje del Popup
  const [isSubmitting, setIsSubmitting] = useState(false); // Estado para controlar el bloqueo del botón
  const [games2, setGames2] = useState([]);
  const [games, setGames] = useState([]);
  const navigate = useNavigate(); // Hook para la redirección


  const getUsers = async () => {
    const usuarios = await APIS_GamesBooks("get", id);
        const usuarios2 = await APIS_GamesBooks("getAll");
    setResok(resok + 1);
    setColocarUsuario(usuarios);
    setColocarUsuario2(usuarios2);
  };

  const handleChangeTables = (e,id) => {
    const { name, value } = e.target;
  
      editGame(id,value);  // Llamar a la función addGame con el valor
    
  };

  const addGame =  (id) => {
    const newGame = {"game":id,"tables":1}

    const existingGame = games2.find((el)=>el.game == id)

    if(existingGame){
      openPopup('The game had already been selected')
    }else{
      setGames2((games) => [...games, newGame]);
    }

    //openPopup

  };

  const getName =  (id) => {

    const existingGame = games?.find((el)=>el.id == id)

    return existingGame?.name
    
  };

  const editGame =  (id,count) => {

    const game = games2.find((el)=>el.id === id)

    game.tables= parseInt(count)

    //setGames2.push(game)
  };

  const deleteGame =  (e,id) => {
    e.preventDefault()

    const game = games2.filter((el)=>el.id !== id)

    setGames2(game)
  };


  useEffect(() => {
    // Verificar si el usuario está autenticado
    const credencialesUsuario = localStorage.getItem('credencialesUsuario'); // Obtener el valor de localStorage
    const isAuthenticated = credencialesUsuario ? JSON.parse(credencialesUsuario).token : null; // Parsear y obtener el token

    if (!isAuthenticated) {
      // Si no está autenticado, redirigir al login
      navigate('/sign-in');
    }
  }, [navigate]);

  useEffect(() => {
    getUsers();
    addGame(id)
  }, []);

  useEffect(() => {
    setCasino(colocarUsuario);
    setGames(colocarUsuario2);
  }, [resok]);

  const userData = useUserStore((state) => state.userData);

   const [formData, setFormData] = useState({
      first_name: "",      
      last_name: "",       
      company: "",        
      email: "",          
      phone: "",          
      eventType: "",      
      eventDate: "",      
      length_of_gaming: "", 
      guests: "",         
      venue: "",          
      event_address: "",   
      city: "",           
      zipCode: "",        
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
    
      if (name === "phone") {
        const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
        if (numericValue.length <= 10) { // Limit to 10 digits
          setFormData({
            ...formData,
            [name]: numericValue,
          });
        }
      } else if (name === "game") {
        addGame(value);  // Llamar a la función addGame con el valor
      } else if (name === "tables") {
        editGame(value);  // Llamar a la función addGame con el valor
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    };
    

  

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string?.slice(1);
  };
  
  
  const openPopup = (message) => {
    setPopupMessage(message);
    setIsPopupOpen(true);

    // Cerrar el popup automáticamente después de 2 segundos
    setTimeout(() => {
      setIsPopupOpen(false);
      
    }, 2000);
  };

  const validateForm = () => {
    for (let key in formData) {
      if (typeof formData[key] === "string"  && formData[key].trim() === "") {
        
        return false;
      }
      
      if (games2 === 0) {
        return false;
      }
    }
    return true;
  };
  const validatePhone = () => {
    return formData.phone.length === 10;
  };

    const onSubmit = async (e) => {
      e.preventDefault();
  
      if (!validateForm()) {
        setPopupMessage("Please fill in all the fields before submitting.");
        setIsPopupOpen(true);
        return;
      }
  
      if (!validatePhone()) {
        setPopupMessage("Please enter a valid U.S. phone number with 10 digits.");
        setIsPopupOpen(true);
        return;
      }
  
      try {
        const data = {
          first_name: formData.first_name,
          last_name: formData.last_name,
          company: formData.company,
          email: formData.email,
          phone: `+1${formData.phone}`, // Ensure the phone number starts with +1
          event_type: formData.eventType,
          event_date: formData.eventDate,
          length_of_gaming: formData.length_of_gaming,
          guests: formData.guests,
          venue: formData.venue,
          event_address: formData.event_address,
          address: formData.event_address,
          city: formData.city,
          zip_code: formData.zipCode,
          customer: userData.user_id,
          game: id //games2,
        };
  
        const response = await APIS_GamesBooks("add", "", data);
  
        if (response && 'success' in response && response.success !== true) {
          const fieldWithCapitalizedFirstLetter = capitalizeFirstLetter(response?.errors[0]?.field);
          openPopup(`${fieldWithCapitalizedFirstLetter}: ` + `${response.errors[0].message}`);
        } else {
          openPopup("Thank you for contacting us. We will get back to you soon!");
  
          setFormData({
            first_name: "",
            last_name: "",
            eventDate: "",
            city: "",
            guestCount: "",
            length_of_gaming: "",
            email: "",
            phone: "",
            message: "",
          });
        }
  
        setIsPopupOpen(true);
      } catch (error) {
        setPopupMessage("There was an error submitting your reservation. Please try again.");
        setIsPopupOpen(true);
      }
    };


  // Función para cerrar el Popup
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="contact-main-container">
      <img
        className="leftContactbg1 contact-floating-img1"
        src={leftContactbg1}
        alt=""
      />
      <img
        className="leftContactbg2 contact-rotation-img"
        src={leftContactbg2}
        alt=""
      />
      <img
        className="rightContactbg1 contact-floating-img2"
        src={rightContactbg}
        alt=""
      />
      <div className="contact-container">
        <span className="contact-header cormorant-font gradient-text">
          Game table reservation
        </span>

        <form className="contact-form" onSubmit={onSubmit}>
  <div className="contact-form-group">
    <label htmlFor="firstName" className="contact-form-label">
      First Name
    </label>
    <input
      type="text"
      className="contact-form-control gordita-font"
      id="firstName"
      name="first_name"
      value={formData.first_name}
      onChange={handleChange}
      placeholder="First Name"
    />
  </div>
  
  <div className="contact-form-group">
    <label htmlFor="lastName" className="contact-form-label">
      Last Name
    </label>
    <input
      type="text"
      className="contact-form-control gordita-font"
      id="lastName"
      name="last_name"
      value={formData.last_name}
      onChange={handleChange}
      placeholder="Last Name"
    />
  </div>

  <div className="contact-form-group">
    <label htmlFor="company" className="contact-form-label">
      Company
    </label>
    <input
      type="text"
      className="contact-form-control gordita-font"
      id="company"
      name="company"
      value={formData.company}
      onChange={handleChange}
      placeholder="Company Name"
    />
  </div>

  <div className="contact-form-group">
    <label htmlFor="email" className="contact-form-label">
      Email
    </label>
    <input
      type="email"
      className="contact-form-control gordita-font"
      id="email"
      name="email"
      value={formData.email}
      onChange={handleChange}
      placeholder="Email Address"
    />
  </div>
  <div className="contact-form-group">
    <label htmlFor="phone" className="contact-form-label">
      Phone
    </label>
    <input
      type="tel"
      className="contact-form-control gordita-font"
      id="phone"
      name="phone"
      value={formData.phone}
      onChange={handleChange}
      placeholder="Phone Number"
    />
  </div>

  <div className="contact-form-group">
    <label htmlFor="eventType" className="contact-form-label">
      Event Type
    </label>
    <input
      type="text"
      className="contact-form-control gordita-font"
      id="eventType"
      name="eventType"
      value={formData.eventType}
      onChange={handleChange}
      placeholder="Event Type"
    />
  </div>

  <div className="contact-form-group">
    <label htmlFor="eventDate" className="contact-form-label">
      Date of Event
    </label>
    <input
      type="date"
      className="contact-form-control gordita-font"
      id="eventDate"
      name="eventDate"
      value={formData.eventDate}
      onChange={handleChange}
    />
  </div>

  <div className="contact-form-group">
  <label htmlFor="length_of_gaming" className="contact-form-label">
    Length of Gaming
  </label>
  <select
    className="contact-form-control gordita-font"
    id="lengthOfGaming"
    
    name="length_of_gaming"
    value={formData.length_of_gaming}
    onChange={handleChange}
  >
    <option value="">Select length</option>
    <option value="2h">2 Hours</option>
    <option value="3h">3 Hours</option>
    <option value="4h">4 Hours</option>
    <option value="5h">5 Hours</option>
    <option value="6h">6 Hours</option>
    <option value="7h">7 Hours</option>
    <option value="8h">8 Hours</option>
    <option value="1d">1 Day</option>
    <option value="2d">2 Days</option>
    <option value="3d">3 Days</option>
    <option value="1w">1 Week</option>
  </select>
</div>

{ 
<div className="contact-form-group">
  <label htmlFor="length_of_gaming" className="contact-form-label">
    Game
  </label>
  <select
    className="contact-form-control gordita-font"
    id="game"
    
    name="game"
    onChange={handleChange}
  >
{games?.map(option => (
    <option key={option.id} value={option.id}>
      {option.name}
    </option>
  ))}
  </select>
</div>

}
{games2?.length > 0 ?
  <div className="Game-table">
  <div className="singleGame-table">
      <div className="singleGame-table-name">
        <h2>Game</h2>
      </div>
      <div className="singleGame-table-input">
        <p>Tables</p>
      </div>
      <div className="singleGame-table-delete">
        <p>Actions</p>
      </div>
    </div>
  {games2.map(option => (
    <div className="singleGame-table" key={option.game}>
      <div className="singleGame-table-name">
        <p>{getName(option.game)}</p>
      </div>
      <div className="singleGame-table-input">
        <input type="number" min='1' max='1000' id="tables" name="tables" onChange={(e)=>handleChangeTables(e,option.game)} placeholder={option.tables}/>
      </div>
      <div className="singleGame-table-delete">
        <button onClick={(e) => deleteGame(e,option.game)}>Delete</button>
      </div>
    </div>
  ))}
  </div> : null }

  <div className="contact-form-group">
    <label htmlFor="guests" className="contact-form-label">
      Guests
    </label>
    <input
      type="number"
      className="contact-form-control gordita-font"
      id="guests"
      name="guests"
      value={formData.guests}
      onChange={handleChange}
      placeholder="Number of Guests"
      min="1"
      max="1000"
    />
  </div>

  <div className="contact-form-group">
  <label htmlFor="venue" className="contact-form-label">
    Venue
  </label>
  <select
    className="contact-form-control gordita-font"
    id="venue"
    name="venue"
    value={formData.venue}
    onChange={handleChange}
  >
    <option value="">Select venue</option>
    <option value="indoor">Indoor</option>
    <option value="outdoor">Outdoor</option>
  </select>
</div>


  <div className="contact-form-group">
    <label htmlFor="eventAddress" className="contact-form-label">
      Event Address
    </label>
    <input
      type="text"
      className="contact-form-control gordita-font"
      id="eventAddress"
      name="event_address"
      value={formData.event_address}
      onChange={handleChange}
      placeholder="Event Address"
    />
  </div>

  <div className="contact-form-group">
    <label htmlFor="city" className="contact-form-label">
      City
    </label>
    <input
      type="text"
      className="contact-form-control gordita-font"
      id="city"
      name="city"
      value={formData.city}
      onChange={handleChange}
      placeholder="Enter your city"
    />
  </div>

  <div className="contact-form-group">
    <label htmlFor="zipCode" className="contact-form-label">
      Zip Code
    </label>
    <input
      type="text"
      className="contact-form-control gordita-font"
      id="zipCode"
      name="zipCode"
      value={formData.zipCode}
      onChange={handleChange}
      placeholder="Zip Code"
      maxLength="10"
    />
  </div>

  <div
    style={{
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      width: "100%",
    }}
  >
    <button type="submit" className="contact-btn">
      SEND MESSAGE
      <img src={sendIcon} alt="sendIcon" />
    </button>
  </div>
</form>
      </div>

      {/* Popup para mostrar mensajes */}
      <Popup
        title="Alert"
        message={popupMessage}
        isOpen={isPopupOpen}
        onClose={handleClosePopup}
      />
    </div>
  );
};

export default Request;
